<template>
  <div v-loading="loading" :class="themeClass" class="width-fill bg-fff my-account">
      <div class="title font-size18 font-weight700">我的账户({{userinfo.fCommerceType}})</div>
      <div class="tip-line flex-row">
          <div class="flex-row tip font-color-FF9900 padding-l-15">
              <i class="iconfont icon-tishi5 font-size18"></i><div class="margin-l-5">请设置支付密码!</div>
          </div>
          <div v-if="isSetPass" class="btn-set-pass margin-l-10 text-center pointer" @click="setPaymentPassword">修改支付密码</div>
          <div v-else class="btn-set-pass margin-l-10 text-center pointer" @click="setPaymentPassword">设置支付密码</div>
      </div>
      <div class="flex-row-space-between margin-t-13">
        <div class="content" v-if="userinfo.fCommerceTypeID==1">
            <!-- 个人用户才有现金钱包 -->
            <div class="top border-F2F2F2">
                <div class="title font-size16">现金钱包</div>
                <div class="flex-row-space-between-center top-content">
                    <div class="left padding-lr-15">
                        <div v-if="cpcnBankCard==null" class="btn-open margin-b-12 font-size14 font-color-fff text-center pointer" @click="openCashWallet">开通现金钱包</div>
                        <div class="flex-column-align-center">
                            <div class="num color-theme font-size20 font-weight">{{moneyDashWalletInfo.fEndBalance|NumFormat}}</div>
                            <div class="text color-theme font-size13">余额</div>
                        </div>
                    </div>
                    <div class="right flex-row-center-center">
                        <div>
                            <svg-icon class="svgimage" icon-class="NoRightsAndInterests" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 是个人用户,而且开卡成功 -->
            <div v-if="cpcnBankCard!=null&&userinfo.fCommerceTypeID==1" class="bottom border-F2F2F2 margin-t-15">
                <div class="title margin-l-13 font-size16 margin-tb-10">中金绑定账户</div>
                <div class="card radius10">
                    <div class="card-top">
                        <div class="bank flex-row">
                            <div class="bank-icon flex-row-center-center"><svg-icon icon-class="UnionPay" /></div>
                            <div class="bank-name font-size16 font-weight700">{{cpcnBankCard.fBankName}}</div>
                        </div>
                        <div class="cardID font-color-333 font-size20" v-html="$options.filters.BankCardFormat(cpcnBankCard.fAccountNumber)"></div>
                    </div>
                    <div class="userinfo flex-row-space-between-center font-size13">
                        <div class="font-color-666">
                            <div class="username">账户名:{{cpcnBankCard.fAccountName}}</div>
                            <div v-if="cpcnBankCard.fPhone!=null" class="tele margin-t-10">手机号:{{cpcnBankCard.fPhone|formatPhone}}</div>
                        </div>
                        <div class="unbundling color-theme pointer">解绑</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="top border-F2F2F2">
                <div class="title font-size16">账服通钱包</div>
                <div class="top-content flex-row-space-between-center">
                    <div class="left padding-lr-15">
                        <div v-if="AccountPayWalletInfo==null" class="btn-open font-size14 font-color-fff text-center pointer" @click="openAccountPayWallet">开通账服通钱包</div>
                        <div v-if="AccountPayWalletInfo!=null" class="wallet-item-content-left flex-column-align-center">
                            <div class="num color-theme font-size20 font-weight margin-t-12">{{AccountPayWalletInfo.fEndBalance|NumFormat}}</div>
                            <div class="text color-theme font-size13">余额</div>
                        </div>
                    </div>
                    <div class="flex-row-center-center right">
                        <div>
                            <svg-icon class="svgimage" icon-class="NoRightsAndInterests" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 个人用户的账服通才有银行卡 -->
            <div v-if="acsBankCard!=null&&userinfo.fCommerceTypeID==1" class="bottom border-F2F2F2 margin-t-15">
                <div class="title margin-l-13 font-size16 margin-tb-10">账服通绑定账户</div>
                <div class="card radius10">
                    <div class="card-top">
                        <div class="bank flex-row">
                            <div class="bank-icon flex-row-center-center"><svg-icon icon-class="UnionPay" /></div>
                            <div class="bank-name font-size16 font-weight700">{{acsBankCard.fBankName}}</div>
                        </div>
                        <div class="cardID font-color-333 font-size20" v-html="$options.filters.BankCardFormat(acsBankCard.fAccountNumber)"></div>
                    </div>
                    <div class="userinfo flex-row-space-between-center font-size13">
                        <div class="font-color-666">
                            <div class="username">账户名:{{acsBankCard.fAccountName}}</div>
                            <div v-if="acsBankCard.fPhone!=null" class="tele margin-t-10">手机号:{{acsBankCard.fPhone|formatPhone}}</div>
                        </div>
                        <div class="unbundling color-theme pointer" @click="unbundling">解绑</div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
    // 个人用户有现金钱包和账服通,有开户,有开卡,个人用户只有要注册现金钱包就是开通的
    // 企业用户只有账服通,没有开卡,只有开户
    // 判断是否绑卡是根据银行卡接口返回的obj是不是null,是null就是没绑卡
    // 判断是否开通账服通是根据可以查账服通余额的接口,返回obj是null就是没开通,不是null就是开通了,返回obj中有fEndBalance是账户余额
import {
		mapGetters
	} from 'vuex';
export default {
  name:"",
  components: {},
  props: {},
  data() {
    return {
        loading:true,
        userinfo:{},
        moneyDashWalletInfo:{},//现金钱包信息
        AccountPayWalletInfo:{},//账服通钱包信息
        cpcnBankCard:{},//现金钱包银行卡信息
        acsBankCard:{},//账服通银行卡信息
		isSetPass:false,//是否已设置密码
    };
  },
  created() {
    //   this.getIsSetPass()
      this.getBaseinfo()
  },
  mounted() {

  },
  methods: {
    //   获取客商基本信息
    getBaseinfo(){
        this.ApiRequestPost('/api/mall/ebcustomer/baseinfo/get-by-current-user-customer-base-info-full').then(
			result => {
                console.log(result,'00000000000');
                if(result.obj!=null){
                    this.userinfo=result.obj
                    console.log(this.userinfo);
                    // fCommerceTypeID:1 个人   2 企业
                    // 个人用户只要注册成功就开通了现金钱包
                    if(this.userinfo.fCommerceTypeID==1){
                        this.getCpcnPhysicalAccount()//获取现金钱包余额
                        this.getMoneyDashBalance()//获取中金银行卡信息
                    }
                    // 判断是不是开通了账服通,未开通返回obj等于null
                    this.getAccountPayWalletInfo()
                    // 查询账服通银行卡信息,未开卡返回obj等于null
                    this.getAcsPhysicalAccount()
                    this.loading=false
                }
			},
			rej => {
                // this.$message({
                //     message: '请完善资料进行认证后再进行操作',
                //     type: 'warning'
                // });
                // this.$router.push({path:'/about/myStuff'})
            }
		);
    },
	//   验证支付密码是否设置
	getIsSetPass() {
		this.ApiRequestPost('/api/mall/ebcustomer/baseinfo/exist-walletpwd').then(
			result => {
				console.log(result, '支付密码是否设置');
				this.isSetPass=result.obj==1?true:false
				console.log(this.isSetPass)
			},
			rej => {}
		);
	},
    //   获取现金钱包余额
    getMoneyDashBalance(){
        this.ApiRequestPost('/api/mall/ebbalance/customer-cash/get-my-cash').then(
			result => {
                    this.moneyDashWalletInfo=result.obj[0]
                console.log(result.obj[0],'111111111111');
			},
			rej => {}
		);
    },
    //   获取账服通钱包余额
    getAccountPayWalletInfo(){
        this.ApiRequestPost('/api/mall/ebcustomer/bank-account/get-acs-virtual-account').then(
			result => {
                    this.AccountPayWalletInfo=result.obj
                console.log(this.AccountPayWalletInfo,'2222222222222');
			},
			rej => {}
		);
    },
    //   中金绑定银行卡查询
    getCpcnPhysicalAccount(){
        this.ApiRequestPost('/api/mall/ebcustomer/bank-account/get-cpcn-physical-account').then(
			result => {
                console.log(result.obj,'3333333333333');
                this.cpcnBankCard=result.obj
			},
			rej => {}
		);
    },
    //   账服通绑定银行卡查询
    getAcsPhysicalAccount(){
        this.ApiRequestPost('/api/mall/ebcustomer/bank-account/get-acs-physical-account').then(
			result => {
                console.log(result.obj,'44444444444444');
                this.acsBankCard=result.obj
			},
			rej => {}
		);
    },
    // 解绑
    unbundling(){},
    // 开通现金钱包,必然没有用户信息
    openCashWallet(){
        this.$router.push({path:'/PersonalContent/individualUsersBindingCard',query:{title:'现金钱包'}})//跳转个人用户绑卡
    },
    // 开通账服通
    openAccountPayWallet(){
        if(this.userinfo.fCommerceTypeID==1){
            this.$router.push({path:'/PersonalContent/individualUsersBindingCard',query:{title:'账服通'}})//跳转个人用户绑卡
        }else if(this.userinfo.fCommerceTypeID==2){        
            this.$router.push({path:'/PersonalContent/enterpriseUsersBindingCard',query:{title:'账服通'}})//跳转企业用户绑卡
        }
    },
    setPaymentPassword(){
        this.$router.push({path:'/PersonalContent/paymentPassword'})
    }
  },
  filters: {
    //格式化银行账户
	BankCardFormat: function (value) {
		// value = value.toString();
		if (value && value.length > 8) {
			return (`${value.substring(0, 4)}`+"<span style='margin:0px 5px;letter-spacing:4.5px'>"+`${"·".repeat(value.length - 8).replace(/(.{4})/g, `
				$1 `)}`+"</span>"+`${value.slice(-4)}`);
		}
		return value;
	},
	formatPhone:function (val) {
		return `${val.substring(0,3)}****${val.substring(val.length-3)}`
	}
  },
  computed: {
    ...mapGetters(['getThemeName']),
	themeClass() {
		return `theme-${this.getThemeName}`;
    },
    ...mapGetters(['getUserInfo']),
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.color-theme {
	@include themify($themes) {
		color: themed('themes_color');
	}
}
.bg-color-theme {
	@include themify($themes) {
		background-color: themed('themes_color');
	}
}
.content{
    width: 440px;
    .title{
        height: 24px;
        line-height: 24px;
        margin-top: 8px;
        margin-left: 15px;
    }
    .top{
        height: 199px;
        .left{
            width: 160px;
            .btn-open{
                width: 160px;
                height: 40px;
                line-height: 40px;
                opacity: 1;
                background: #2e66f8;
                font-family: '阿里巴巴普惠体', '阿里巴巴普惠体'-400;
            }
            .num{
                    height: 24px;
                    line-height: 24px;
                    opacity: 1;
                }
                .text{
                    height: 17px;
                    line-height: 17px;
                }
        }
        .right{
            height: 167px;
            flex: 1;
            .svgimage{
                width: 179px;
                height: 151px;
            }
        }
    }
    .bottom{
        height: 230px;
        .card{
                width: 405px;
                height: 167px;
                opacity: 1;
                background: #e1efff;
                overflow: hidden;
                margin-left: 15px;
                .card-top{
                    width: 405px;
                    height: 85px;
                    opacity: 1;
                    background: #f8f9fe;
                    box-sizing: border-box;
                    padding: 15px;
                    .bank{
                        height: 19px;
                        line-height: 19px;
                        .bank-icon{
                            width: 34px;
                            height: 18px;
                        }
                        .bank-name{
                            margin-left: 5px;
                        }
                    }
                    .cardID{
                        height: 24px;
                        margin-top: 12px;
                    }
                }
                .userinfo{
                    padding-left: 19px;
                    padding-top: 14px;
                }
                .unbundling{
                    margin-right: 21px;
                    height: 16px;
                    line-height: 16px;
                }
                
            }
    }
}
.margin-l-5{
    margin-left: 5px;
}
.margin-b-12{
    margin-bottom: 12px;
}
.margin-t-65{
    margin-top: 65px;
}
.margin-l-71{
    margin-left: 71px;
}
.my-account{
    height: 100%;
    margin-top: 10px;
    padding: 0 13px;
    overflow: hidden;
    .title{
        height: 24px;
        line-height: 24px;
        margin-top: 8px;
    }
    .tip-line{
        height: 40px;
        line-height: 40px;
        margin-top: 12px;
        .tip{
            flex: 1;
            background-color: #FFF5E8;
        }
        .btn-set-pass{
            width: 120px;
            height: 40px;
            opacity: 1;
            background: #2e66f8;
            border-radius: 2px;
            font-size: 14px;
            font-family: '阿里巴巴普惠体', '阿里巴巴普惠体'-400;
            font-weight: 400;
            color: #ffffff;
        }
    }
}
</style>
